<template>
  <div style="background-color: #fff; height: 100%; width: 100%">
    <div id="baiduMap" style="width: 100%; height: 500px"></div>
  </div>
</template>
<script>
export default {
  name: "BaiduMap",
  data() {
    return {
      stationList: [],
    };
  },
  methods: {
    getList() {
      console.log(this.marker);
      this.$http("api1", "/api/asset/ChargeStation/all", "get").then((res) => {
        if (res.data.code == 0) {
          this.$message.success("修改成功");
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    // 初始化地图
    var map = new BMapGL.Map("baiduMap");
    map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    var scaleCtrl = new BMapGL.ScaleControl(); // 添加比例尺控件
    map.addControl(scaleCtrl);
    var zoomCtrl = new BMapGL.ZoomControl(); // 添加缩放控件
    map.addControl(zoomCtrl);
    var cityControl = new BMapGL.CityListControl({
      // 控件的停靠位置（可选，默认左上角）
      anchor: BMAP_ANCHOR_TOP_LEFT,
      // 控件基于停靠位置的偏移量（可选）
      offset: new BMapGL.Size(10, 5),
    });
    this.$http("api1", "/api/asset/ChargeStation/all", "get").then((res) => {
      if (res.data.code == 0) {
        this.stationList = res.data.data;
        this.stationList.forEach((item) => {
          var point = new BMapGL.Point(
            item.lonLat.split(",")[0],
            item.lonLat.split(",")[1]
          );
          var marker = new BMapGL.Marker(point);
          map.addOverlay(marker);
        });
      }
    });

    // 将控件添加到地图上
    map.addControl(cityControl);
    map.centerAndZoom("济源市", 13);
    var opts = {
      width: 200,
      height: 100,
      title: "故宫博物院",
    };
    var infoWindow = new BMapGL.InfoWindow(
      "地址：北京市东城区王府井大街88号乐天银泰百货八层",
      opts
    );
    // 点标记添加点击事件
    marker.addEventListener("click", function () {
      map.openInfoWindow(infoWindow, point); // 开启信息窗口
    });
    // 其他地图操作...
  },
  // ...
};
</script>
